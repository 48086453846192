import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: (): any =>
      import('../app/modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'tabs',
    loadChildren: () => import('./modules/side-menu/side-menu.module').then( m => m.SideMenuPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'plans',
    loadChildren: (): any =>
      import('./modules/plan/plan.module').then((m) => m.PlanPageModule),
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'inventory',
    loadChildren: () => import('./modules/inventory/inventory.module').then( m => m.InventoryPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
