declare const require: any;

export const environment = {
  production: true,
  appVersion: require('../../package.json').version,
  firebaseConfig: {
    apiKey: 'AIzaSyBaKzV0Ddf2LJXUptdBKoowJARLIWvoRvE',
    authDomain: 'alert-beacon.firebaseapp.com',
    projectId: 'alert-beacon',
    storageBucket: 'alert-beacon.appspot.com',
    messagingSenderId: '518192195662',
    appId: '1:518192195662:web:450315b44beb7d37143b1f',
  },
  urls: {
    api: 'https://trackapi.alertnetworks.net/bnet',
    termsOfService: 'https://alertnetworks.net/assets/terms_of_service',
    assetImageUrl:
      'https://track.alertnetworks.net/images/equipment/Restoration/',
    reports: 'https://track.alertnetworks.net/r/bnet/abn/auto-login',
    universalMap: 'https://www.google.com/maps/search/?api=1&query=',
    markerClustererImagePath:
      'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
    sentryDsn:
      'https://68bdb5c95a5e4d5483d01bb1e23abc19@sentry.alertnetworks.net/13',
    openStreetMapGeoApi: 'https://geoapi.alertnetworks.net/api',
    traccarApi: 'https://trackapi.alertnetworks.net/5055/v1/',
  },
  weather: {
    url: 'https://api.openweathermap.org/data/2.5/weather',
    weatherApiKey: '023f08b6a3078653f81c14e115516f38',
  },
  youtubeVideoId: 'Z9AvmXK1Y8s',
  projectId: 'alert-beacon',
  inAppPurchase:{
    appleKey:'appl_HbyXotzidFrzjspuUzARFMeMcKs',
    androidKey:'goog_vIPCUjPzZhzRCLkJeLyImAJyJyC'
  },
  mapClusterConfig: {
    clusterColor: "#243548",
    clusterFontColor: "#FFFFFF"
  }
};
