import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  constructor(private translate: TranslateService) {}

  async initLanguage() {
    this.translate.addLangs(['en', 'es']);
    const lang = localStorage.getItem("language");
    this.translate.setDefaultLang(lang || "en");
  }

  async setLanguage(value: string) {
    this.translate.use(value).subscribe(async (event) => {
      localStorage.setItem("language", value);
    });
  }

  public getTranslateKey(key: string) {
    return this.translate.instant(key);
  }
}
