import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { UserDataService } from '../services/data/user-data.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private userDataService: UserDataService,
    private router: Router
  ) {}

  public async canActivate(): Promise<boolean> {
    const user = this.userDataService.getUser();

    if (user) {
      return true;
    }

    this.router.navigateByUrl('auth/sign-in');

    return false;
  }
}
