import { Injectable } from "@angular/core";
import { PushNotifications } from "@capacitor/push-notifications";
import { StorageService } from "../utils/storage.service";
import { NavigationExtras, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class PushNotificationService {
  constructor(private storageService: StorageService, private router: Router) {}

  async registerNotifications() {
    this.addListeners();
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === "prompt" || permStatus.receive === "denied") {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== "granted") {
      throw new Error("User denied permissions!");
    }

    const res = await PushNotifications.register();
    console.log("register push notification", res);
  }

  async addListeners() {
    await PushNotifications.addListener("registration", async (token) => {
      console.info("Registration token: ", token);
      if (token?.value) {
        await this.storageService.set("push_token", token.value);
      }
    });

    await PushNotifications.addListener("registrationError", (err) => {
      console.error("Registration error: ", err.error);
    });

    await PushNotifications.addListener(
      "pushNotificationReceived",
      (notification) => {
        console.log("Push notification received: ", notification);
      }
    );

    await PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification) => {
        console.log(
          "Push notification action performed",
          notification
        );
        const { id, type } = notification?.notification?.data;
        switch (type) {
          case "location":
            this.router.navigateByUrl(`tabs/locations/${id}`);
            break;
          case "asset":
            this.router.navigateByUrl(`tabs/assets/${id}`);
            break;
          case "locationAssets":
            const nav: NavigationExtras = {
              state: { segment: "assets" },
            };
            this.router.navigateByUrl(`tabs/locations/${id}`, nav);
            break;

          default:
            break;
        }
      }
    );
  }
}
