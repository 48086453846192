import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';

import { find, orderBy } from 'lodash';

import { UserModel } from 'src/app/core/models/user.model';
import { CompanyModel } from '../../models/company.model';

import { StorageService } from '../utils/storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  userChanged$: Observable<UserModel>;
  isAdminUserChanged$: Observable<boolean>;
  signInEmailChanged$: Observable<string>;
  accessTokenChanged$: Observable<string>;
  refreshTokenChanged$: Observable<string>;
  isRefreshedTheAppChanged$: Observable<boolean>;
  isSignedOutChanged$: Observable<boolean>;
  userRoleChanged$: Observable<string>;
  activeCompanyChanged$: Observable<CompanyModel>;

  private userSubject: BehaviorSubject<UserModel>;
  private isAdminUserSubject: BehaviorSubject<boolean>;
  private signInEmailSubject: BehaviorSubject<string>;
  private accessTokenSubject: BehaviorSubject<string>;
  private refreshTokenSubject: BehaviorSubject<string>;
  private isRefreshedTheAppSubject: BehaviorSubject<boolean>;
  private isSignedOutSubject: BehaviorSubject<boolean>;
  private userRoleSubject: BehaviorSubject<string>;
  private activeCompanySubject: BehaviorSubject<CompanyModel>;

  constructor(private storageService: StorageService, private router: Router) {
    this.initialize();
  }

  setAuthState(): void {
    const user = this.getUser();

    if (user) {
      this.router.navigateByUrl('tabs/home');
    }
  }

  setUser(user: UserModel): void {
    const activeCompany = find(user?.companies, (g) => g.isCurrent === 'Y');

    if (!activeCompany) {
      return;
    }

    this.setIsAdminUser(activeCompany?.userRole === 'Admin');
    this.setUserRole(activeCompany?.userRole);
    this.setActiveCompany(activeCompany); // default company

    user = {
      ...user,
      companyId: activeCompany?.companyId,
      userInfo: { ...user?.userInfo, userRole: activeCompany.userRole },
      companies: orderBy(user.companies, ['companyName'], ['asc']),
    };

    this.userSubject.next(user);

    this.storageService.set<UserModel>('user', user);
    this.storageService.set<string>('email', user?.userInfo?.email); //keep email after Sign out
  }

  removeUser(): void {
    this.userSubject.next(null);
  }

  getUser(): UserModel {
    return this.userSubject.getValue();
  }

  setIsAdminUser(isAdminUser: boolean): void {
    this.isAdminUserSubject.next(isAdminUser);
    this.storageService.set<boolean>('isAdminUser', isAdminUser);
  }

  getIsAdminUser(): boolean {
    return this.isAdminUserSubject.getValue();
  }

  setSignInEmail(email: string): void {
    this.signInEmailSubject.next(email);
  }

  getSignInEmail(): string {
    return this.signInEmailSubject.getValue();
  }

  setAccessToken(accessToken: string): void {
    this.accessTokenSubject.next(accessToken);
    this.storageService.set<string>('accessToken', accessToken);
  }

  getAccessToken(): string {
    return this.accessTokenSubject.getValue();
  }

  setRefreshToken(refreshToken: string): void {
    this.refreshTokenSubject.next(refreshToken);
    this.storageService.set<string>('refreshToken', refreshToken);
  }

  getRefreshToken(): string {
    return this.refreshTokenSubject.getValue();
  }

  setIsRefreshedTheApp(isRefreshed: boolean): void {
    this.isRefreshedTheAppSubject.next(isRefreshed);
  }

  setIsSignedOut(isSignedOut: boolean): void {
    this.isSignedOutSubject.next(isSignedOut);
  }

  setUserRole(userRole: string): void {
    this.userRoleSubject.next(userRole);
  }

  getUserRole(): string {
    return this.userRoleSubject.getValue();
  }

  getActiveCompany(): CompanyModel {
    return this.activeCompanySubject.getValue();
  }

  private setActiveCompany(company: CompanyModel): void {
    const previousCompnay = this.getActiveCompany();
    if(company?.companyId !== previousCompnay?.companyId) {
      this.activeCompanySubject.next(company);
    }
  }

  private initialize(): void {
    this.userSubject = new BehaviorSubject<UserModel>(null);
    this.userChanged$ = this.userSubject.asObservable();

    this.isAdminUserSubject = new BehaviorSubject<boolean>(false);
    this.isAdminUserChanged$ = this.isAdminUserSubject.asObservable();

    this.signInEmailSubject = new BehaviorSubject<string>(null);
    this.signInEmailChanged$ = this.signInEmailSubject.asObservable();

    this.accessTokenSubject = new BehaviorSubject<string>(null);
    this.accessTokenChanged$ = this.accessTokenSubject.asObservable();

    this.refreshTokenSubject = new BehaviorSubject<string>(null);
    this.refreshTokenChanged$ = this.refreshTokenSubject.asObservable();

    this.isRefreshedTheAppSubject = new BehaviorSubject<boolean>(null);
    this.isRefreshedTheAppChanged$ =
      this.isRefreshedTheAppSubject.asObservable();

    this.isSignedOutSubject = new BehaviorSubject<boolean>(null);
    this.isSignedOutChanged$ = this.isSignedOutSubject.asObservable();

    this.userRoleSubject = new BehaviorSubject<string>(null);
    this.userRoleChanged$ = this.userRoleSubject.asObservable();

    this.activeCompanySubject = new BehaviorSubject<CompanyModel>(null);
    this.activeCompanyChanged$ = this.activeCompanySubject.asObservable();
  }
}
