import { Injectable } from '@angular/core';

import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private toastController: ToastController,
    private translateService: TranslateService
  ) {}

  async showToast(
    message: string,
    color: string,
    position: any = 'top',
    duration: number = 5000
  ): Promise<HTMLIonToastElement> {
    const tString = await this.translateService.instant([message, 'buttons.ok']);
    const toast: HTMLIonToastElement = await this.toastController.create({
      message: tString[message],
      duration,
      position,
      color,
      buttons: [
        {
          text: tString['buttons.ok'],
          handler: (): any => {},
        },
      ],
    });

    toast.present();

    return toast;
  }

  async showErrorToast(msg = 'oops_something_went_wrong') {
    await this.showToast('toast.danger.' + msg, 'danger');
  }
}
