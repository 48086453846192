import { Component, OnDestroy, OnInit } from '@angular/core';

import { NavController, Platform } from '@ionic/angular';

import { Subscription } from 'rxjs';

import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';

import * as Sentry from '@sentry/capacitor';

import { environment } from 'src/environments/environment';

import { UserModel } from './core/models/user.model';
import { LanguageService } from "./core/services/app/language.service";
import { AuthService } from './core/services/apis/auth.service';
import { StorageService } from './core/services/utils/storage.service';
import { UserDataService } from './core/services/data/user-data.service';
import { SettingsDataService } from './modules/settings/shared/services/data/settings-data.service';
import { PushNotificationService } from './core/services/notification/push-notification.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  private isRefreshedTheAppSubscription: Subscription;
  private isSignedOutSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private storageService: StorageService,
    private userDataService: UserDataService,
    private settingsDataService: SettingsDataService,
    private platform: Platform,
    private pushNotificationService: PushNotificationService,
    private navController: NavController,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.initializeApp();
  }

  ngOnDestroy(): void {
    this.isRefreshedTheAppSubscription?.unsubscribe();
    this.isSignedOutSubscription?.unsubscribe();
  }

  private async initializeApp(): Promise<void> {

    this.settingsDataService.initialize(); //need this to set `isAlwaysOnEnabled=true` by default

    const [
      user,
      email,
      accessToken,
      refreshToken,
      isAdminUser,
    ] = await Promise.all([
      this.storageService.get<UserModel>('user'),
      this.storageService.get<string>('email'),
      this.storageService.get<string>('accessToken'),
      this.storageService.get<string>('refreshToken'),
      this.storageService.get<boolean>('isAdminUser'),
    ]);

    if (user) {
      this.userDataService.setUser(user);
    }

    this.userDataService.setAccessToken(accessToken);
    this.userDataService.setRefreshToken(refreshToken);
    this.userDataService.setIsAdminUser(isAdminUser);

    if (email) {
      this.userDataService.setSignInEmail(email); //Remember email when user logs out
    }

    // this.settingsDataService.setIsAlwaysOnEnabled(isAlwaysOnEnabled);
    // this.settingsDataService.setIsRealtimeTrackingEnabled(
    //   isRealtimeTrackingEnabled
    // );

    this.userDataService.setAuthState();

    this.refreshTheApp();
    this.signOutTheApp();

    this.disableHardwareBackButton();

    this.storageService.remove('searchTerm');

    this.setSentryScopeAndTags();
    this.languageService.initLanguage();

    setTimeout(() => {
      SplashScreen.hide();
    }, 2000);

    if(this.platform.is('capacitor')){
      this.pushNotificationService.registerNotifications();
    }
  }



  private signOut(): void {
    this.authService.signOut().subscribe();
    this.storageService.remove('user');
    this.storageService.remove('push_token');
    this.userDataService.setIsRefreshedTheApp(true);
  }

  // set clean state for the app when 'Sign Out' use case
  private refreshTheApp(): void {
    this.isRefreshedTheAppSubscription =
      this.userDataService.isRefreshedTheAppChanged$.subscribe((res) => {
        if (!res) {
          return;
        }

        this.userDataService.setIsRefreshedTheApp(null);
        this.navController.navigateRoot('auth/sign-in',{replaceUrl: true}).then(()=>{
            if(!this.platform.is('mobile')) {
              window.location.reload();
            }
        });
      });
  }

  private signOutTheApp(): void {
    this.isSignedOutSubscription =
      this.userDataService.isSignedOutChanged$.subscribe((res) => {
        if (!res) {
          return;
        }

        this.signOut();
      });
  }

  private disableHardwareBackButton(): void {
    this.platform.backButton.subscribeWithPriority(401, () => {}); //this works with modals too
  }

  private setSentryScopeAndTags(): void {
    if (Capacitor.getPlatform() !== 'web') {
      return; // Sentry works only on Native platforms
    }

    Sentry.configureScope((scope) => {
      scope.setTag('projectId', environment.projectId);
    });
  }
}
