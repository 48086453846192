import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';

import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { AuthService } from '../services/apis/auth.service';
import { UserDataService } from '../services/data/user-data.service';
import { ToastService } from '../services/utils/toast.service';

import { TokenModel } from '../models/token.model';
import { StorageService } from '../services/utils/storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private authService: AuthService,
    private userDataService: UserDataService,
    private toastService: ToastService,
    private storageService: StorageService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authRequest = request;

    if (authRequest.url.indexOf('openweathermap') >= 0) {
      //no need token for weather API
      return next.handle(authRequest.clone());
    }

    if (authRequest.url.indexOf('vtrack') >= 0) {
      //no need token for traccar API
      return next.handle(authRequest.clone());
    }

    if (authRequest.url.indexOf('geo') >= 0) {
      //no need token for openStreetMap Geo API
      return next.handle(
        authRequest.clone({
          setHeaders: {
            // hardcoded move to environment.ts
            Authorization: 'Basic ' + btoa('alertnetworks:fHr4pZVxKCP2j9#6'),
          },
        })
      );
    }

    const accessToken = this.userDataService.getAccessToken();

    if (accessToken != null) {
      authRequest = this.addTokenHeader(request, accessToken);
    }

    return next.handle(authRequest).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          !authRequest.url.includes('auth/login') &&
          error.status === 401
        ) {
          if(authRequest.url.includes('auth/logout')){
            this.storageService.remove('user');
            this.storageService.remove('push_token');
            this.userDataService.setIsRefreshedTheApp(true);
            return throwError(error);
          }
          return this.handle401Error(authRequest, next, error);
        } else if (error instanceof HttpErrorResponse && error.status === 555) {
          this.toastService.showToast(error?.error?.statusMessage, 'warning'); //reached the limit

          return throwError(error);
        }

        return throwError(error);
      })
    );
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler,
    error: HttpErrorResponse
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const refreshToken = this.userDataService.getRefreshToken();

      if (refreshToken) {
        return this.authService.refreshToken(refreshToken).pipe(
          switchMap((token: TokenModel) => {
            this.isRefreshing = false;

            this.userDataService.setAccessToken(token?.accessToken);
            this.userDataService.setRefreshToken(token?.refreshToken);

            this.refreshTokenSubject.next(token.accessToken); //get the 'accessToken' from refreshToken() call

            return next.handle(this.addTokenHeader(request, token.accessToken));
          }),
          catchError((err) => {
            this.isRefreshing = false;

            this.userDataService.setIsSignedOut(true); //Sign Out the app

            return throwError(err);
          })
        );
      } else {
        this.isRefreshing = false;
        return throwError(error); //email or pw incorrect use case:401
      }
    } else {
      this.isRefreshing = false;

      this.userDataService.setIsSignedOut(true); //Sign Out the app: refreshToken expire use case
    }

    return this.refreshTokenSubject.pipe(
      filter((accessToken) => accessToken !== null),
      take(1),
      switchMap((accessToken) =>
        next.handle(this.addTokenHeader(request, accessToken))
      )
    );
  }

  private addTokenHeader(
    request: HttpRequest<any>,
    accessToken: string
  ): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        'Access-Token': accessToken,
      },
    });
  }
}
