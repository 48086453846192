import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { StorageService } from 'src/app/core/services/utils/storage.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsDataService {
  isAlwaysOnEnabledChanged$: Observable<boolean>;
  isRealtimeTrackingEnabledChanged$: Observable<boolean>;

  private isAlwaysOnEnabledSubject: BehaviorSubject<boolean>;
  private isRealtimeTrackingEnabledSubject: BehaviorSubject<boolean>;

  constructor(private storageService: StorageService) {
    this.initialize();
  }

  setIsAlwaysOnEnabled(isAlwaysOnEnabled: boolean): void {
    this.isAlwaysOnEnabledSubject.next(isAlwaysOnEnabled);

    // this.storageService.set<boolean>('isAlwaysOnEnabled', isAlwaysOnEnabled);
  }

  setIsRealtimeTrackingEnabled(isRealtimeTrackingEnabled: boolean): void {
    this.isRealtimeTrackingEnabledSubject.next(isRealtimeTrackingEnabled);

    // this.storageService.set<boolean>(
    //   'isRealtimeTrackingEnabled',
    //   isRealtimeTrackingEnabled
    // );
  }

  initialize(): void {
    this.isAlwaysOnEnabledSubject = new BehaviorSubject<boolean>(false);
    this.isAlwaysOnEnabledChanged$ =
      this.isAlwaysOnEnabledSubject.asObservable();

    this.isRealtimeTrackingEnabledSubject = new BehaviorSubject<boolean>(false);
    this.isRealtimeTrackingEnabledChanged$ =
      this.isRealtimeTrackingEnabledSubject.asObservable();

    // this.setDefaultValues();
  }

  private async setDefaultValues(): Promise<void> {
    const isAlwaysOnEnabled = await this.storageService.get<boolean>(
      'isAlwaysOnEnabled'
    );

    this.storageService.set<boolean>(
      'isAlwaysOnEnabled',
      isAlwaysOnEnabled ? true : false
    );

    const isRealtimeTrackingEnabled = await this.storageService.get<boolean>(
      'isRealtimeTrackingEnabled'
    );

    this.storageService.set<boolean>(
      'isRealtimeTrackingEnabled',
      isRealtimeTrackingEnabled ? true : false
    );
  }
}
