import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KeyValuePipe } from '@angular/common';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { Capacitor } from '@capacitor/core';

import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';

import * as Sentry from '@sentry/capacitor';
import * as SentryAngular from '@sentry/angular-ivy';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppComponent } from './app.component';
import { TranslateHttpLoader  } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';

import { environment } from '../environments/environment';
import { Purchases } from "@awesome-cordova-plugins/purchases/ngx";
import { HttpClient } from '@angular/common/http';

if (Capacitor.getPlatform() !== 'web') {
  //works only for native platforms

  Sentry.init(
    {
      dsn: environment.production ? environment.urls.sentryDsn : '', //run only on prod mode
      //dsn: environment.urls.sentryDsn, //run only on dev mode

      release: `alert-beacon@${environment.appVersion}`,
      // Set your dist version, such as "1"
      dist: '1',
    },
    // Forward the init method from @sentry/angular
    SentryAngular.init
  );
}


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ swipeBackEnabled: false, mode: 'ios' }),
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    CoreModule,
    BrowserAnimationsModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Insomnia,
    InAppBrowser,
    OpenNativeSettings,
    {
      provide: ErrorHandler,
      // Attach the Sentry ErrorHandler
      useValue: SentryAngular.createErrorHandler(),
    },
    KeyValuePipe,
    Purchases
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
