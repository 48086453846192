import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpService } from './generic/http.service';

import { environment } from 'src/environments/environment';

import { UserModel } from 'src/app/core/models/user.model';
import { UserInfoModel } from '../../models/user-info.model';
import { TokenModel } from '../../models/token.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private httpService: HttpService) {}

  refreshToken(token: string): Observable<TokenModel> {
    return this.httpService.post<TokenModel>(
      `${environment.urls.api}/auth/refresh_token`,
      {
        token,
      }
    );
  }

  signUp(userInfo: UserInfoModel): Observable<UserModel> {
    const { firstName, lastName, email, companyName }: UserInfoModel = userInfo;

    return this.httpService.post<UserModel>(
      `${environment.urls.api}/auth/register`,
      {
        firstName,
        lastName,
        email,
        companyName,
      }
    );
  }

  signIn(userInfo: UserInfoModel): Observable<UserModel> {
    const { email, password }: UserInfoModel = userInfo;

    return this.httpService.post<UserModel>(
      `${environment.urls.api}/auth/login`,
      {
        email,
        password,
      }
    );
  }

  forgotPassword(userInfo: UserInfoModel): Observable<UserInfoModel> {
    const { email }: UserInfoModel = userInfo;

    return this.httpService.post<UserInfoModel>(
      `${environment.urls.api}/auth/forgot_password`,
      {
        email,
      }
    );
  }

  signOut(): Observable<void> {
    return this.httpService.post<void>(
      `${environment.urls.api}/auth/logout`,
      {}
    );
  }
}
